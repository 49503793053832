import VueRouter from 'vue-router'
const Page = () =>
    import ('@/components/pagesComponents/Page');
const Index = () =>
    import ("@/components/childComponents/Index");
const About = () =>
    import ('@/components/childComponents/About');
const Company = () =>
    import ('@/components/childComponents/about/Company');
const Research = () =>
    import ('@/components/childComponents/about/Research');
const Contact = () =>
    import ('@/components/childComponents/Contact');
const Service = () =>
    import ('@/components/childComponents/Service');
const Products = () =>
    import ('@/components/childComponents/Products');
const Product1 = () =>
    import ('@/components/childComponents/products/Product1');
const Product2 = () =>
    import ('@/components/childComponents/products/Product2');
const Product3 = () =>
    import ('@/components/childComponents/products/Product3');


const router = new VueRouter({
    routes: [{
        path: '/',
        component: Page,
        children: [{
                path: 'index',
                component: Index
            },
            {
                path: 'about',
                component: About,
                children: [{
                        path: 'company',
                        component: Company
                    },
                    {
                        path: 'research',
                        component: Research
                    }
                ]
            },
            {
                path: 'products',
                component: Products,
                children: [{
                        path: 'product1',
                        component: Product1
                    },
                    {
                        path: 'product2',
                        component: Product2
                    },
                    {
                        path: 'product3',
                        component: Product3
                    },
                ]
            },
            {
                path: 'contact',
                component: Contact
            },
            {
                path: 'service',
                component: Service
            }
        ]
    }]
})

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        router.push('/index');
    } else {
        next();
    }
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router